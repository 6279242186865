<template>
  <TosListVuetify entityName="locode" :headers="headers" :validateFunction="validate" >
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.codice___LIKE" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Descrizione" v-model="slotProps.data.descrizione___LIKE" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Nazione" v-model="slotProps.data.codNazione" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('codice') + '*'"  v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
            </v-col>
            <v-col cols="3">
              <v-select label="Nazione '*'"  v-model="slotProps.data.codNazione" :items="nazioni" :rules="[rules.required]" :error-messages="slotProps.errors.codNazione" />
            </v-col>
            <v-col cols="6">
              <v-text-field :label="tLabel('descrizione') + '*'"  v-model="slotProps.data.descrizione" :rules="[rules.required]" :error-messages="slotProps.errors.descrizione" />
            </v-col>
        </v-row>
      </div>
    </template>

  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Locode",
  data() {
    return {
      nazioni: [],
      headers: [
        { value: "action",  text: ""},
        { value: "codice",  text: "Codice"},
        { value: "descrizione", text: "Descrizione"},
        { value: "codNazione",  text: "Nazione"},
      ],
    };
  },
  service: null,
  created() {
  },
  async mounted(){
    this.nazioni=await this.$api.get(this.$apiConfiguration.BASE_PATH+"nazioni/shortList");
  },
  methods: {
  validate(item, errors) {
      if(item.codice.length!=5) errors.add("codice","Deve essere 5 caratteri");
    }
  },
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>